import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39d1ea70"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "detailModal",
  class: "modal show",
  tabindex: "-1",
  role: "dialog"
}
const _hoisted_2 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "message" }
const _hoisted_6 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("i", {
            class: _normalizeClass(_ctx.dialog.icon)
          }, null, 2),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.dialog.messageLabel), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.dialog.btnSuccessLabel)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "btn safe-btn-modal safe-btn-success-modal",
                onClick: _cache[0] || (_cache[0] = ($event: any) => {
              _ctx.dialog.btnSuccessFunction();
              _ctx.detailModal?.hide();
            })
              }, _toDisplayString(_ctx.dialog.btnSuccessLabel), 1))
            : _createCommentVNode("", true),
          (_ctx.dialog.btnCancelLabel)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                type: "button",
                class: "btn safe-btn-modal safe-btn-success-empty-modal",
                "data-dismiss": "modal",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.btnCancelFunction && _ctx.btnCancelFunction(...args)))
              }, _toDisplayString(_ctx.dialog.btnCancelLabel), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}