import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "main-content" }
const _hoisted_2 = {
  key: 0,
  class: "app-loading-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_bar_menu = _resolveComponent("side-bar-menu")!
  const _component_n_spin = _resolveComponent("n-spin")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_dialog_confirmation = _resolveComponent("dialog-confirmation")!
  const _component_dialog_batch = _resolveComponent("dialog-batch")!
  const _component_InfoDialog = _resolveComponent("InfoDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_side_bar_menu),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.appLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_n_spin, {
              class: "app-loading",
              size: "large",
              show: true
            })
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          (_openBlock(), _createBlock(_KeepAlive, { include: ['SearchAnimal', 'SearchPortability', 'SearchSale'] }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ], 1024))
        ]),
        _: 1
      }),
      _createVNode(_component_dialog_confirmation),
      _createVNode(_component_dialog_batch),
      _createVNode(_component_InfoDialog)
    ])
  ], 64))
}