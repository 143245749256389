const envConfig = (window as any).safeAppConfig || {};

export const Environment = {
    APP_PROPERTIES: {
        AUTH_URL: envConfig.VUE_APP_AUTH_URL || "http://localhost:3001",
        AUTH_REALM: envConfig.VUE_APP_AUTH_REALM || "iRancho",
        API_URL: envConfig.VUE_APP_API_PRODUCER_URL || "http://localhost:3000",
        API_ADMIN_URL: envConfig.VUE_APP_API_ADMIN_URL || "http://localhost:3001",
        BASE_URL: envConfig.VUE_APP_BASE_URL || "",
        PAGE_SIZE: toNumber(envConfig.VUE_APP_PAGE_SIZE) || 25,
    }
}

function toBool(a: any) {
    return a.toLowerCase() == "true";
}

function toNumber(a: any) {
    return Number(a).valueOf();
}