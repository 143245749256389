import User from "@/models/user/user.model";
import router from "@/router";
import axios, { AxiosInstance } from "axios";
import jwt from "jsonwebtoken";
import qs from "qs";
import { Environment } from "@/config/config";
import apiClient from "@/common/http-common";
import { AxiosResponse } from 'axios';

const AUTH_URL = Environment.APP_PROPERTIES.AUTH_URL;
const REALM = Environment.APP_PROPERTIES.AUTH_REALM;
const LOGIN_PATH = `auth/realms/${REALM}/protocol/openid-connect/token`;
const USER_INFO_PATH = `/auth/realms/${REALM}/protocol/openid-connect/userinfo`

const keycloakApiClient: AxiosInstance = axios.create({
  timeout: 15 * 1000,
  baseURL: AUTH_URL,
  headers: {
    // "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
  },
});

keycloakApiClient.interceptors.response.use((response) => response, (error) => {
  return Promise.reject(error)
})
export class AuthService {

  static async validateAccessToken() {    
    try {
      await apiClient.get(`${AUTH_URL}${USER_INFO_PATH}`);
    } catch(e) {
      localStorage.removeItem("sso");
      router.replace({name: "login"});
    }
  }

  static hasValidRefreshToken(_sso: any = null) {
    const sso = _sso || AuthService.getSSO();

    if (!sso || !sso.refresh_token)
      return false;

    const payload: any = jwt.decode(sso.refresh_token);

    if (!payload)
      return false;
    
    const now = Math.floor(Date.now() / 1000);

    return payload.exp > now;
  }

  static hasValidAccessToken(_sso: any = null) {
    const sso = _sso || AuthService.getSSO();

    if (!sso || !sso.access_token)
      return false;

    const payload: any = jwt.decode(sso.access_token);

    if (!payload)
      return false;
    
    const now = Math.floor(Date.now() / 1000);

    return payload.exp > now;
  }

  static async login(user: User) {
    const tokenValid = await AuthService.hasValidAccessToken();
    if (tokenValid) {
      return true;
    }

    const data = qs.stringify({
      grant_type: "password",
      username: user.username,
      password: user.password,
    });

    const response: AxiosResponse<any, any> = await keycloakApiClient.post(LOGIN_PATH, data);
    
    if (response.data.access_token) {
      localStorage.setItem("sso", JSON.stringify(response.data));
      return true;
    }

    return false;
  }

  static logout() {
    localStorage.removeItem("sso");
    router.replace({ name: "login" })
  }

  static refresh(sso: any): Promise<any> {
    if (!sso.refresh_token) return Promise.resolve(false);

    const data = qs.stringify({
      grant_type: "refresh_token",
      refresh_token: sso.refresh_token,
    });

    return keycloakApiClient.post(LOGIN_PATH, data).then((response: any) => {
      if (response?.data?.access_token) {
        localStorage.setItem("sso", JSON.stringify(response.data));
      }

      return true;
    });
  }

  static getSSO() {
    const ssoJson = localStorage.getItem("sso");

    if (!ssoJson)
      return null;

    return JSON.parse(ssoJson);
  }
}
