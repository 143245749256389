
import GlobalEvent from "@/common/global-event";
import { dateTimeFilter } from "@/filters/custom-filters";
import batchServices from "@/services/batch.service";
import { NDataTable } from "naive-ui";
import { defineComponent } from "vue";
const bootstrap = require("bootstrap");

const createColumns = () => {
  return [
    {
      title: "Tipo",
      key: "tipo",
      sorter: "default",
    },
    {
      title: "Assinante",
      key: "nomeAssinante",
      sorter: "default",
    },
    {
      title: "Documento",
      key: "documentoAssinante",
      sorter: "default",
    },
    {
      title: "Certificado Digital",
      key: "certificadoDigitalEntidade",
      sorter: "default",
    },
  ];
};

const DialogBatch = defineComponent({
  components: { NDataTable },

  data() {
    return {
      batch: {
        idBlockchain: "",
        idSistemaGestao: "",
        dtCriacao: "",
        dtAlteracao: "",
        dtFinalizacao: "",
        dono: "",
        assinaturas: [],
      },
      modalBatch: null as any,
      columns: createColumns(),
    };
  },
  methods: {
    dateTimeFilter,
    btnCancelFunction() {
      this.modalBatch.hide();
    },

    getBatch(idLoteSync: string) {
      GlobalEvent.emit("appLoading", true);
      batchServices
        .getBatchById(idLoteSync)
        .then((response: any) => {
          this.batch = response.data;
          this.modalBatch.show();
        })
        .finally(() => GlobalEvent.emit("appLoading", false));
    },
  },
  mounted() {
    this.modalBatch = new bootstrap.Modal(
      document.getElementById("modalBatch"),
      {}
    );

    GlobalEvent.on("dialog-batch", (idLoteSync: any) => {
      this.getBatch(idLoteSync);
    });
  },
})
export default DialogBatch;
