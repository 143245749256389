
import User from "@/models/user/user.model";
import router from "@/router";
import { AuthService } from "@/services/auth.service";
import GlobalEvent from "@/common/global-event";
import { defineComponent } from "@vue/runtime-core";
import DialogConfirmation from "@/components/template/DialogConfimation.vue";

const Login = defineComponent({
  components: {
    DialogConfirmation,
  },
  data() {
    return {
      username: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    doLogin() {
      this.loading = true;
      AuthService.login(new User(this.username, this.password))
        .then((success) => {
          this.loading = false;
          if (success) {
            router.push({ name: "buscarAnimal" });
          }
        })
        .catch((e: any) => {
          this.loading = false;
          GlobalEvent.emit("loginDialog", {
            icon: "bi bi-exclamation-circle",
            messageLabel: "Falha ao fazer login. Verifique seu usuário e senha",
            btnSuccessLabel: "Entendi",
            btnSuccessFunction: () => {
              GlobalEvent.emit("dialog", "hide");
            },
          });
        });
    },
  },
  beforeMount() {
    AuthService.logout();
  },
});
export default Login;
