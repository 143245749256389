
import GlobalEvent from "@/common/global-event";
import { defineComponent } from "@vue/runtime-core";
const bootstrap = require("bootstrap");

interface InfoDialogDataItem {
	label: string,
	key: string,
	filter?: typeof Function,
}
const InfoDialog = defineComponent({
  data() {
    return {
			title: "",
      fields: new Array<InfoDialogDataItem>(),
			data: null as any,
      detailModal: null as any,
    };
  },
  methods: {
  },
  unmounted() {
    GlobalEvent.off("infoDialog");
  },
  mounted() {
    this.detailModal = new bootstrap.Modal(
      (this.$refs["infoModal"] as HTMLElement),
      {}
    );

    GlobalEvent.on('infoDialog', (payload: string | {fields: any, data: any} | any) => {
      if (payload == "hide") {
        this.detailModal?.hide();
        return;
      }

			this.title = payload.title;
      this.fields = payload.fields;
			this.data = payload.data;
      this.detailModal?.show();
    });
  },
});
export default InfoDialog;
