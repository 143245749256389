
import GlobalEvent from "@/common/global-event";
import DialogBatch from "@/components/animal/DialogBatch.vue";
import DialogConfirmation from "@/components/template/DialogConfimation.vue";
import InfoDialog from "@/components/template/InfoDialog.vue";
import SideBarMenu from "@/components/template/SideBarMenu.vue";
import { NSpin } from "naive-ui";
import { AuthService } from "@/services/auth.service";
import { defineComponent } from "@vue/runtime-core";

const App = defineComponent({
  data() {
    return {
      appLoading: false,
    };
  },
  components: {
    SideBarMenu,
    DialogConfirmation,
    DialogBatch,
    NSpin,
    InfoDialog,
  },
  mounted() {
    AuthService.validateAccessToken();
    GlobalEvent.on("appLoading", (loading: any) => {
      this.appLoading = loading;
    });
  },
  watch: {},
});
export default App;
