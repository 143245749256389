
import { Options, Vue } from "vue-class-component";
import GlobalEvent from "@/common/global-event";
import { AuthService } from "@/services/auth.service";
import { defineComponent } from "@vue/runtime-core";

const SideBarMenu = defineComponent({
  mounted() {
    GlobalEvent.on("sidebar", (arg) => {
      this.sidebarForceExpand = !this.sidebarForceExpand;
    });
  },
  computed: {},
  methods: {
    showUserAccount() {
      this.isActiveMenuItemAccount = !this.isActiveMenuItemAccount;
    },

    handleLogout() {
      GlobalEvent.emit("dialog", {
        icon: "bi bi-exclamation-circle",
        messageLabel: "Tem certeza que deseja Sair do iRancho ?",
        btnSuccessLabel: "Sim",
        btnCancelLabel: "Cancelar",
        btnSuccessFunction: () => {
          AuthService.logout();
        },
        btnCancelFunction: () => {
          GlobalEvent.emit("dialog", "hide");
        }
      });
    },

    toggleSidebar() {
      this.sidebarForceExpand = !this.sidebarForceExpand;
    },
  },
  data() {
    return {
      isActiveMenuItemAccount: false,
      sidebarForceExpand: false,
    };
  },
})
export default SideBarMenu;
