
import GlobalEvent from "@/common/global-event";
import { defineComponent } from "@vue/runtime-core";
const bootstrap = require("bootstrap");

const DialogConfirmation = defineComponent({
  props: {
    eventName: {
      type: String,
      default: () => "dialog"
    }
  },
  data() {
    return {
      dialog: {
        icon: "",
        messageLabel: "",
        btnSuccessLabel: "",
        btnCancelLabel: "",
        btnSuccessFunction: () => {},
        btnCancelFunction: () => {},
      },
      detailModal: null as any,
    };
  },
  methods: {
    btnCancelFunction() {
      this.dialog.btnCancelFunction();
      this.detailModal?.hide();
    },
  },
  mounted() {
    this.detailModal = new bootstrap.Modal(
      document.getElementById("detailModal"),
      {}
    );

    GlobalEvent.on(this.eventName, (payload: any) => {
      if (payload == "hide") {
        this.detailModal?.hide();
        return;
      }

      this.dialog = payload;
      this.detailModal?.show();
    });
  },
})
export default DialogConfirmation;
