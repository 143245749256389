
export function currencyFilter(value?: number) {
    return value?.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
    });
}

export function weightFilter(value?: number | string) {
    return (value ? value : '?') + ' Kg';
}

export function booleanFilter(value?: boolean) {
    return value ? 'SIM' : 'NÃO'
}

export function cpfFilter(value?: string) {
    let cpf = value?.replace(/[^\d]/g, "");
    return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function dateFilter(value?: string) {
    if (!value) {
        return "";
    }

    const date = new Date(value);
    let month = (date.getUTCMonth() + 1).toString(); //months from 1-12
    month = (month.length == 1) ? '0' + month : month;
    let day = date.getUTCDate().toString();
    day = (day.length == 1) ? '0' + day : day;
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
}

export function dateTimeFilter(value?: string) {
    if (!value) {
        return "";
    }

    const date = new Date(value);
    let month = (date.getUTCMonth() + 1).toString(); //months from 1-12
    month = (month.length == 1) ? '0' + month : month;
    let day = date.getUTCDate().toString();
    day = (day.length == 1) ? '0' + day : day;
    const year = date.getUTCFullYear();
    let hour = ("0" + date.getUTCHours()).slice(-2);
    let minutes = ("0" + date.getUTCMinutes()).slice(-2);
    let seconds = ("0" + date.getUTCSeconds()).slice(-2);

    return `${day}/${month}/${year} - ${hour}:${minutes}:${seconds}`;
}

export function underlineToSpace(value?: string) {
    if (!value) {
        return ""
    }

    return value.replace("_", " ");
}
