
import { defineComponent } from "vue";

const Home = defineComponent({
  components: {},
  methods: {
    goToRoute(route: string) {
      this.$router.push(route);
    }
  }
});

export default Home;
