import authHeader from "@/services/auth.header";
import { AuthService } from "@/services/auth.service";
import axios, { AxiosInstance } from "axios";
import router from "@/router";
import { Environment } from "@/config/config";

const apiClient: AxiosInstance = axios.create({
  baseURL: Environment.APP_PROPERTIES.API_ADMIN_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use((req) => {
  const sso = AuthService.getSSO();

  const authHeaders = authHeader(sso);
  req.headers = { ...req.headers, ...authHeaders };
  return req;
});

apiClient.interceptors.response.use(
  (res) => {    
    return res;
  },
  (err) => {
    const sso = AuthService.getSSO();
    const data = err?.response?.data;
    const statusCode = data?.statusCode;

    if (statusCode == 401) {

      if (AuthService.hasValidRefreshToken(sso)) {
        const originalConfig = err.config;
        return AuthService.refresh(sso).then(() => {
          originalConfig._retry = true;

          return apiClient(originalConfig);
        });
      }

      router.replace({name: "home"});
      return Promise.reject("Token expired");
    }

    throw err;
  }
);

export default apiClient;
