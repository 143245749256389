import { AuthService } from "@/services/auth.service";
import GlobalEvent from "@/common/global-event";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import App from "../App.vue";
import Login from "../views/Login.vue";
import { Environment } from "@/config/config";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    name: "home",
    component: Home
  },
  {
    path: "/",
    component: App,
    children: [
      {
        path: "/search-animal",
        name: "buscarAnimal",
        component: () =>
          import(/* webpackChunkName: "SearchAnimal" */ "../views/SearchAnimal.vue"),
      },
      {
        path: "/search-animal/:idAnimal",
        name: "animalDetail",
        component: () => import("../views/AnimalDetail.vue"),
        props: true
        // props: route > ({query: route.query.q})
      },
      {
        path: "/search-portability",
        name: "searchPortability",
        component: () => import("../views/SearchPortability.vue"),
      },
      {
        path: "/search-sale",
        name: "searchSale",
        component: () =>
          import("../views/SearchSale.vue"),
      },
      {
        path: "/history-animal/:idAnimal",
        name: "historyAnimal",
        component: () =>
          import("../views/HistoryAnimal.vue"),
        props: true
      },
      {
        path: "/history-animal/detail/:idAnimal",
        name: "historyAnimalDetail",
        component: () => import("../views/AnimalDetail.vue"),
        props: true
        // props: route > ({query: route.query.q})
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "",
    redirect: { name: "home" }
  },
];

const router = createRouter({
  history: createWebHistory(Environment.APP_PROPERTIES.BASE_URL),
  routes,
});

const publicPathNames = ['login', 'home'];

router.beforeEach((to, from, next) => {
  GlobalEvent.emit("appLoading", true);
  const isAuthenticated = AuthService.hasValidAccessToken();
  if (!publicPathNames.includes(to.name?.toString() || "") && !isAuthenticated) {
    next({ name: "login" });
  }
  next();
});

router.afterEach(() => {
  GlobalEvent.emit("appLoading", false);
})

export default router;
